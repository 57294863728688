import * as _ from 'lodash';

import { ChallengeQuestionContent } from './challenge-question-content';
import { ChallengeTopic } from './challenge-topic';
import { AssessmentQuestionType } from './scoring/assessment-question-type';

const props = [
  'assessmentKey',
  'questionKey',
  'questionType',
  'number',
  'instruction',
  'prompt',
  'answer',
  'content',
  'contentId',
  'correct',
  'audio',
];

export class ChallengeQuestion {
  assessmentKey?: string;
  questionKey?: string;
  number: number;
  instruction: string;
  prompt: string;
  content: string;
  audio?: string;
  questionType: AssessmentQuestionType;
  correct: string[];
  choices: string[];
  answer: string[];

  private contentId: string;
  private _questionContent?: ChallengeQuestionContent;
  private _topics: ChallengeTopic[];
  private _topicKeys?: string[];

  constructor(values: {[key: string]: any}) {
    // Assigns simple column keys.
    props.forEach((key: string) => {
      this[key] = values[key] ? values[key] : null;
    });

    this.number = values.number ? parseInt(values.number, 10) : 0;

    // Store topicKeys.
    if (values.topics && values.topics.map) {
      this._topicKeys = values.topics.map(t => t.topicKey);
      this.topics = values.topics;
    } else {
      this._topicKeys = values.topics ? _.split(values.topics, ' ') : [];
    }

    if (values.choices) {
      this.choices = values.choices;
    } else {
      // Maps the choices into an indexed array.
      this.choices = [values.choice1, values.choice2, values.choice3, values.choice4];
    }
  }

  set topics(topics: ChallengeTopic[]) {
    this._topics = topics;
  }

  get topics(): ChallengeTopic[] {
    return this._topics ? this._topics : [];
  }

  get hasSharedContent(): boolean {
    return !!this.contentId;
  }

  set topicKeys(value: string[]) {
    this._topicKeys = value;
  }

  get topicKeys(): string[] {
    return this._topicKeys;
  }

  get contentKey(): string {
    return this.contentId;
  }

  set questionContent(value: ChallengeQuestionContent) {
    this._questionContent = value;
  }

  get questionContent(): ChallengeQuestionContent {
    return this._questionContent ? this._questionContent : null;
  }

  get questionContentIsImage(): boolean {
    return this.questionContent
      && (this.questionContent.content.endsWith('png') || this.questionContent.content.endsWith('jpg'));
  }

  static sort(a: ChallengeQuestion, b: ChallengeQuestion): number {
    if (a.number < b.number) {
      return -1;
    }
    return a.number > b.number ? 1 : 0;
  }
}
