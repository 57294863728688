import { ActivityResult } from '@models/activity-result';
import { ActivityItemResult } from '@models/scoring/activity-item-result';
import { TopicActivityType } from '@models/topic-activity-type';
import { IActivityGameResult } from '../../../topic-activity/topic-activity-shared/topic-activity-models/game-result';

export class GumActivityGameResult implements IActivityGameResult {
  constructor(activityResult: ActivityResult[]) {
    const activities = activityResult
      .map(a => a.items)
      .reduce((acc, val) => acc.concat(val), []);

    this.fillInTheBlankResults = activities.filter(a => a.activityType === TopicActivityType.FillInTheBlank);
    this.highlightResults = activities.filter(a => a.activityType === TopicActivityType.Highlight);
    this.identifyResults = activities.filter(a => a.activityType === TopicActivityType.Identify);
    this.rewriteResults = activities.filter(a => a.activityType === TopicActivityType.Rewrite);
    this.multipleChoiceResults = activities.filter(a => a.activityType === TopicActivityType.MultipleChoice);
    this.sentenceManipulationResults = activities
      .filter(a => a.activityType === TopicActivityType.SentenceManipulation);
  }
  fillInTheBlankResults: ActivityItemResult[];
  highlightResults: ActivityItemResult[];
  identifyResults: ActivityItemResult[];
  rewriteResults: ActivityItemResult[];
  sentenceManipulationResults: ActivityItemResult[];
  multipleChoiceResults: ActivityItemResult[];
}
