import { IActivityGameResult } from '../../topic-activity/topic-activity-shared/topic-activity-models/game-result';
import { ActivityResult } from '../activity-result';
import { ActivityItemResult } from '../scoring/activity-item-result';
import { CompletionResult } from '../scoring/completion-result';

type SpellingGameResultParameter = ActivityResult[] | CompletionResult | null;

export enum SpellingActivityPropertyMap {
  Completion = 'completionResult',
  LetterSearch = 'letterResults',
  FillInTheBlank = 'fillInTheBlankResults',
  Hangman = 'wordAttemptResults',
  Highlight = 'highlightResults',
  Identify = 'identifyResults',
  MultipleChoice = 'multipleChoiceResults',
  Rewrite = 'rewriteResults',
  PatternActivity = 'patternMatchingResults',
  Proofreading = 'wordAttemptResults',
  Unscramble = 'wordAttemptCountResults',
  WordSpellUntimed = 'wordAttemptResults',
  WordSpellTimed = 'wordAttemptResults',
  WordSort = 'wordSortResults',
}

export class SpellingActivityGameResult implements IActivityGameResult {
  constructor(results: SpellingGameResultParameter) {
    if (results && results instanceof CompletionResult) {
      this.completionResult = results;
    } else if (Array.isArray(results) && results.length > 0) {
      results.forEach((result) => {
        const prop = SpellingActivityPropertyMap[result.activityType];
        if (Object.prototype.hasOwnProperty.call(this, prop)) {
          this[prop] = this[prop].concat(result.items);
        }
      });
    }
  }

  completionResult?: CompletionResult = null;
  fillInTheBlankResults: ActivityItemResult[] = [];
  highlightResults: ActivityItemResult[] = [];
  identifyResults: ActivityItemResult[] = [];
  multipleChoiceResults: ActivityItemResult[] = [];
  rewriteResults: ActivityItemResult[] = [];
  patternMatchingResults: ActivityItemResult[] = [];
  letterResults: ActivityItemResult[] = [];
  wordAttemptResults: ActivityItemResult[] = [];
  wordAttemptCountResults: ActivityItemResult[] = [];
  wordSortResults: ActivityItemResult[] = [];
}
