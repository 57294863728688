export abstract class ActivityItemResult {
  constructor(public activityType: string) {
  }

  abstract get correct(): boolean;

  abstract set correct(value: boolean);

  abstract get numAttempts(): number;
}

export class ActivityItemResultBase extends ActivityItemResult {
  correct: boolean = false;
  attempts: number = 0;
  sentence: string = '';

  get numAttempts(): number {
    return this.attempts;
  }
}
