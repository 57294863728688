export enum TopicActivityType {
  FillInTheBlank = 'FillInTheBlank',
  Identify = 'Identify',
  Highlight = 'Highlight',
  SentenceManipulation = 'SentenceManipulation',
  MultipleChoice = 'MultipleChoice',
  Rewrite = 'Rewrite',
  Video = 'Video',
  Writing = 'Writing',
  FreeWrite = 'FreeWrite',
  Trace = 'Trace',
  Completion = 'Completion',
  Test = 'Test',
  PatternActivity = 'PatternActivity',
  Hangman = 'Hangman',
  LetterSearch = 'LetterSearch',
  Proofreading = 'Proofreading',
  Unscramble = 'Unscramble',
  WordSpellTimed = 'WordSpellTimed',
  WordSpellUntimed = 'WordSpellUntimed',
  WordSort = 'WordSort',
  PostTest = 'PostTest',
  PreTest = 'PreTest',
  PracticeTest = 'PracticeTest',
  // Game is a pseudo activity type only for saving spelling scores
  Game = 'Game'
}
