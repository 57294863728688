import { TopicActivityType } from '../topic-activity-type';

import { ActivityItemResult } from './activity-item-result';
import { CompletionWord } from './completion-word';

export interface ICompletionResult {
  wordsCorrect: number;
  placementsCorrect: number;
  possible: number;
  completionResults: CompletionWord[];
}

export class CompletionResult extends ActivityItemResult implements ICompletionResult {
  constructor(
    public activityType: TopicActivityType,
    public wordsCorrect: number,
    public placementsCorrect: number,
    public possible: number,
    public completionResults: CompletionWord[]
  ) {
    super(activityType);
  }

  get correct(): boolean {
    return this.wordsCorrect === this.possible && this.placementsCorrect === this.possible;
  }

  // Not necessary.
  set correct(value: boolean) {}

  // Not necessary.
  get numAttempts(): number {
    return 0;
  }
}
