import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BootstrapService } from '@core/bootstrap.service';
import { ActivityResult } from '@models/activity-result';
import { ApiResponse } from '@models/api-response';
import { Challenge } from '@models/challenge';
import { ChallengeQuestion } from '@models/challenge-question';
import { HandwritingContentType } from '@models/handwriting-content-type';
import { ProductType } from '@models/product-type';
import { IAssessmentQuestionRequest } from '@models/scoring/assessment-question-request';
import { IAssessmentQuestionResponse } from '@models/scoring/assessment-question-response';
import { SpellingActivity } from '@models/spelling/spelling-activity';
import { SpellingActivityGameResult } from '@models/spelling/spelling-activity-game-result';
import { ITopic, ITopicGroup } from '@models/topic';
import { TopicActivity } from '@models/topic-activity';
import { TopicActivityResponse } from '@models/topic-activity-response';
import { TopicActivityType } from '@models/topic-activity-type';
import { TopicType } from '@models/topic-type';
import { Helpers } from '@shared/helpers';
import { Products } from '@shared/products.enum';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GumActivity } from '../gum/gum-shared/models/gum-activity';
import { GumActivityGameResult } from '../gum/gum-shared/models/gum-activity-game-result';
import { IActivity } from '../topic-activity/topic-activity-shared/topic-activity-models/activity';
import { IActivityResponse } from '../topic-activity/topic-activity-shared/topic-activity-models/activity-response';

@Injectable({
  providedIn: 'root',
})
export class TopicActivityService {
  constructor(private http: HttpClient, private bootstrap: BootstrapService) { }

  private getContentUrl(topicKey: string): string {
    const baseUrl = `${environment.apiUrl}/digital-resources/activity/content`;
    const productType = this.bootstrap.getProductType();

    let topicUrl = `/topic/${topicKey}/product/${productType}`;

    topicUrl = this.bootstrap.product === Products.spelling ? `${topicUrl}/level/${this.bootstrap.level}` : topicUrl;

    return `${baseUrl}${topicUrl}`;
  }

  private getNavigationUrl(productType: ProductType, classroomId: string, grade: string): string {
    const baseUrl = `${environment.apiUrl}/digital-resources/navigation/classroom/${classroomId}`;
    return `${baseUrl}?grade=${grade}&productType=${productType}`;
  }

  private getActivityUrl(productType: ProductType): string {
    const prefix = this.getActivityProductPrefix(productType);
    const baseUrl = `${environment.apiUrl}/digital-resources`;
    return this.bootstrap.isStudent ? `${baseUrl}/student/${prefix}/activity` : `${baseUrl}/teacher/${prefix}/activity`;
  }

  private getSaveAssessmentUrl(productType: ProductType): string {
    const baseUrl = `${environment.apiUrl}/digital-resources`;
    let requestUrl = '';
    if (Helpers.isSpellingProduct(productType)) {
      requestUrl = `${baseUrl}/student/spelling/assessment`;
    } else {
      requestUrl = this.bootstrap.isStudent
        ? `${baseUrl}/student/assessment`
        : `${baseUrl}/teacher/assessment`;
    }
    return requestUrl;
  }

  private getAssessmentUrl(assessmentKey, classroomId: string, productType, activityId?: string): string {
    const baseUrl = `${environment.apiUrl}/digital-resources`;
    return activityId && !this.bootstrap.isStudent
      ? `${baseUrl}/teacher/assessment/${activityId}?showPastYearAssessments=${this.bootstrap.isSchoolAdmin}`
      : `${baseUrl}/assessment/${assessmentKey}?productType=${productType}&classroomId=${classroomId}`;
  }

  private getActivityProductPrefix(productType: ProductType): string {
    if (Helpers.isGumProduct(productType)) {
      return 'gum';
    }
    if (Helpers.isHandwritingProduct(productType)) {
      return 'handwriting';
    }
    if (Helpers.isSpellingProduct(productType)) {
      return 'spelling';
    }

    throw new Error(`${productType} is not a supported product for topic-activities`);
  }

  /**
   * Gets the topic navigation for a classroom, product and grade filtered by unit.
   *
   * @todo replace the navigation endpoint with one that gets topics for a unit if the Api provides it.
   */
  getTopicsByTypeAndUnit(classroomId: string, topicKey: string, productType: ProductType, topicType: TopicType, unit: string): Observable<ApiResponse<ITopic[]>> {
    const grade = Helpers.extractGradeKeyFromTopicKey(topicKey);
    const variant = Helpers.mapGradeKeyToVariantKey(grade);
    return this.http.get<ApiResponse<ITopicGroup[]>>(this.getNavigationUrl(productType, classroomId, variant))
      .pipe(
        map(res => new ApiResponse<ITopicGroup[]>(true, res)),
        map((res) => {
          const response: ITopic[] = res.response.reduce((ret: ITopic[], topicGroup: ITopicGroup) => {
            const items = topicGroup.items.filter(topic => topic.topicType === topicType
              && topic.topicKeySuffix.includes(`G${grade}U${unit}T`));
            return items.length > 0 ? ret.concat(items) : ret;
          }, []);
          return new ApiResponse<ITopic[]>(true, { response, messages: [] });
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<ITopic[]>(false, err.error));
        })
      );
  }

  // Gets the topic using topicKey.
  getTopicByTopicKey(classroomId: string, topicKey: string, productType: ProductType): Observable<ApiResponse<ITopic[]>> {
    const grade = Helpers.extractGradeKeyFromTopicKey(topicKey);
    const variant = Helpers.mapGradeKeyToVariantKey(grade);
    return this.http.get<ApiResponse<ITopicGroup[]>>(this.getNavigationUrl(productType, classroomId, variant))
      .pipe(
        map(res => new ApiResponse<ITopicGroup[]>(true, res)),
        map((res) => {
          const response: ITopic[] = res.response.reduce((ret: ITopic[], topicGroup: ITopicGroup) => {
            const items = topicGroup.items.filter(topic => topic.topicKey === topicKey);
            return items.length > 0 ? ret.concat(items) : ret;
          }, []);
          return new ApiResponse<ITopic[]>(true, { response, messages: [] });
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<ITopic[]>(false, err.error));
        })
      );
  }

  /**
   * Gets the topic activity items for a topic identifier.
   *
   * A topic activity item is one individual piece of content. A set of topic items may launch multiple
   * activities (in Practice and Game) such as Sentence Manipulation, 50/50, Fling, etc... The result
   * of an activity may have different activity types combined.
   */
  getTopicItemsForTopicKey(topicKey: string, limit: number = 0, shuffle = false): Observable<TopicActivityResponse> {
    return this.http.get<TopicActivityResponse>(this.getContentUrl(topicKey))
      .pipe(
        map(res => new ApiResponse<TopicActivityResponse>(true, res)),
        map(res => ({
          starEarned: res.response.starEarned,
          contentItems: res.response.contentItems.map((item: any) => ({
            topicKey: item.topicKey,
            topicName: item.topicName,
            topicType: TopicType[item.topicType],
            topicTypeName: item.topicTypeName,
            activityType: TopicActivityType[item.activityType],
            activityOrder: item.activityOrder ? +item.activityOrder : 0,
            content: item.content ? item.content : null,
            prompt: item.prompt ? item.prompt : null,
            correct: item.correct ? item.correct : null,
            hint: item.hint ? item.hint : null,
            draggables: item.draggables ? this.splitItemBank(item.draggables) : null,
            contentType: item.contentType && HandwritingContentType[item.contentType]
              ? HandwritingContentType[item.contentType]
              : null,
            traceSet: item.traceSet ? item.traceSet : null,
            keysToLegibility: item.keysToLegibility ? item.keysToLegibility : null,
            activityStepGroups: item.activityStepGroups ? item.activityStepGroups : null,
          } as TopicActivity))
        } as TopicActivityResponse)),
        map((res: TopicActivityResponse) => {
          let contentItems = res.contentItems.slice(0, (limit > 0 ? limit : res.contentItems.length));
          contentItems = shuffle ? _.shuffle(contentItems) : contentItems;

          return {
            starEarned: res.starEarned,
            contentItems,
          };
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(null);
        }),
      );
  }

  // Gets the activity when working with reviewable activities.
  getActivity(topicKey: string, activityId: string, activeOnly: boolean = true): Observable<ApiResponse<IActivityResponse>> {
    if (this.bootstrap.isStudent || activityId) {
      const productType = this.bootstrap.getProductType();
      const url = this.bootstrap.isStudent
        ? `${this.getActivityUrl(productType)}/topic/${topicKey}/product/${productType}?activeOnly=${activeOnly}`
        : `${this.getActivityUrl(productType)}/${activityId}`;
      return this.http.get(url)
        .pipe(
          map((res: ApiResponse<IActivityResponse>) => {
            // A null response should not be considered a success for teachers.
            const success = this.bootstrap.isStudent || !!res.response;
            return new ApiResponse<IActivityResponse>(success, res);
          }),
          catchError((err: HttpErrorResponse) => {
            console.error(err);
            const errorResponse = new ApiResponse<IActivityResponse>(false, err.error);
            return of(errorResponse);
          })
        );
    }
    return of(new ApiResponse<IActivityResponse>(true));
  }

  // Saves the activity / student work.
  saveActivity(activity: IActivity): Observable<ApiResponse<IActivityResponse>> {
    if (this.bootstrap.isStudent) {
      return this.http.put(this.getActivityUrl(activity.productType), activity)
        .pipe(
          map(res => new ApiResponse<IActivityResponse>(true, res)),
          catchError((err: HttpErrorResponse) => {
            console.error(err);
            const errorResponse = new ApiResponse<IActivityResponse>(false, err.error);
            return of(errorResponse);
          })
        );
    }
    return of(new ApiResponse<IActivityResponse>(true, { response: null, messages: [] }));
  }

  saveActivityFromResults(topicKey: string, activityResults: ActivityResult[], activityType?: TopicType): Observable<ApiResponse<IActivityResponse>> {
    const productType = this.bootstrap.getProductType();
    let activity: IActivity;

    if (Helpers.isGumProduct(productType)) {
      activity = {
        gameRequest: new GumActivityGameResult(activityResults)
      } as GumActivity;
      // @todo Handle other product types if necessary
    } else {
      activity = {
        gameRequest: new SpellingActivityGameResult(activityResults),
        wordListLevel: this.bootstrap.level,
      } as SpellingActivity;

      if (activityType === TopicType.ExtraPatternPractice) {
        // This ActivityType does not exist in the content spreadsheets but needs
        // to be set for Digital Resources to save extra pattern practice spelling activities.
        activity.activityType = TopicActivityType.Game;
      } else if (TopicActivityType[activityType]) {
        activity.activityType = TopicActivityType[activityType];
      }
    }

    activity.topicKey = topicKey;
    activity.productType = productType;
    activity.grade = Helpers.mapGradeToGradeType(this.bootstrap.grade);
    activity.classroomId = this.bootstrap.classroomId;

    return this.saveActivity(activity);
  }

  reviewActivity(activityId: string, activity: IActivity): Observable<ApiResponse<IActivityResponse>> {
    if (this.bootstrap.isTeacher) {
      return this.http.patch(`${this.getActivityUrl(activity.productType)}/${activityId}`, activity)
        .pipe(
          map(res => new ApiResponse<IActivityResponse>(true, res)),
          catchError((err: HttpErrorResponse) => {
            console.error(err);
            const errorResponse = new ApiResponse<IActivityResponse>(false, err.error);
            return of(errorResponse);
          })
        );
    }
    return of(new ApiResponse<IActivityResponse>(true));
  }

  // eslint-disable-next-line default-param-last
  getAssessmentForId(assessmentKey: string, classroomId: string, productType: ProductType = ProductType.DigitalResourcesGum2021, activityId?: string): Observable<ApiResponse<Challenge>> {
    const url = `${this.getAssessmentUrl(assessmentKey, classroomId, productType, activityId)}`;
    return this.http.get(url)
      .pipe(
        map((res: ApiResponse<Challenge>) => {
          // set topic keys
          const challenge = res.response as Challenge;
          challenge.questions = res.response.questions.map((q) => {
            const question = new ChallengeQuestion(q);
            question.topicKeys = q.topics.map(t => t.topicKey);
            question.questionContent = q.questionContent;
            question.answer = q.answer;
            return question;
          });
          return new ApiResponse<Challenge>(true, { response: challenge, messages: res.messages });
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          const errorResponse = new ApiResponse<Challenge>(false, err.error);
          return of(errorResponse);
        })
      );
  }

  saveAssessment(assessmentRequest: IAssessmentQuestionRequest): Observable<ApiResponse<IAssessmentQuestionResponse>> {
    if (this.bootstrap.isStudent) {
      return this.http.patch(this.getSaveAssessmentUrl(assessmentRequest.productType), assessmentRequest)
        .pipe(
          map(res => new ApiResponse<IAssessmentQuestionResponse>(true, res)),
          catchError((err: HttpErrorResponse) => {
            console.error(err);
            const errorResponse = new ApiResponse<IAssessmentQuestionResponse>(false, err.error);
            return of(errorResponse);
          })
        );
    }
    return of(new ApiResponse<IAssessmentQuestionResponse>(true));
  }

  /**
   * Splits the item bank character by character.
   *
   * The item bank is a space-separated list of characters, words, or phrases:
   *    1. "a b c" maps to  [a, b, c].
   *    2. "cat dog bird" maps to [cat, dog, bird].
   *    3. "'a b' 'c d' 'e f'" maps to [a b, c d, e f].
   *    4. "'a 'b' 'c d' 'e f'" maps to [a 'b, c d, e f].
   */
  splitItemBank(bank: string): string[] {
    const items: string[] = [];
    let isPhrase = false;
    let item = '';
    bank.trim().split('').forEach((char: string, index: number, splitBank) => {
      if ((index === 0 || item.length === 0) && index + 1 < splitBank.length) {
        // Evaluates to determine whether the current item will either be considered a phrase or a single
        // word/character.
        if (char !== `'` && char !== ' ') {
          item = char;
        } else if (char === `'`) {
          isPhrase = true;
        }
      } else if (index + 1 === splitBank.length) {
        // Makes sure to add the final character to its item and push it onto the stack.
        if (!isPhrase || char !== `'`) {
          item = `${item}${char}`;
        }
        items.push(item);
        item = '';
        isPhrase = false;
      } else if (char === `'`) {
        // Adds single quotes that are not a part of a phrase to the item or pushes the item onto the
        // stack.
        if (isPhrase && (splitBank.length === (index + 1) || splitBank[index + 1] === ' ')) {
          items.push(item);
          item = '';
          isPhrase = false;
        } else {
          item = `${item}${char}`;
        }
      } else if (!isPhrase && char === ' ') {
        // Pushes the item on the stack when character is space (i.e. separator) and not a phrase.
        items.push(item);
        item = '';
        isPhrase = false;
      } else {
        item = `${item}${char}`;
      }
    });
    return items;
  }
}
